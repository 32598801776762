import ArticlePreview from '../components/article-preview'
import Button from '../components/button';
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import React from 'react'
import blogPost from './blog.module.css'
import get from 'lodash/get'
import { graphql } from 'gatsby'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const exhibitions = get(this, 'props.data.allContentfulExhibition.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    return (
      <Layout location={this.props.location} exhibitions={exhibitions}>
        <Helmet title={siteTitle} />
        <div className={blogPost.wrapper}>
          <div className={blogPost.leftColumn}>
            <h1 className={blogPost.title}>About Tommy</h1>
            {
              author.node.shortBio.shortBio.split('\n').map(text => (
                <p>{text}</p>
              ))
            }
            <Button to="/contact">Contact</Button>
          </div>
          <div className={blogPost.rightColumn}>
            <Img alt={author.node.title} fluid={author.node.image.fluid} />
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulExhibition {
      edges {
        node {
          title
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          image {
            fluid(maxWidth: 500, background: "rgb:000000") {
              ...GatsbyContentfulFluid
            }
          }
          shortBio {
            shortBio
          }
          title
        }
      }
    }
  }
`
